.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiContainer-root {
  margin-top: 50px;
}

.MuiTypography-h4 {
  margin-bottom: 20px;
}

.MuiPaper-root {
  padding: 20px;
}

.MuiTable-root {
  margin-top: 20px;
}

.MuiTableCell-head {
  font-weight: bold;
}

.MuiTableCell-body {
  padding: 10px;
}
